import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import './ImageSection.css';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const ImageSection = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const windowSize = useWindowSize();
  const imagesPerView = windowSize < 768 ? 1 : 3;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://quirckart.in/api/adnew/');
        const data = await response.json();

        const fetchedImages = [];
        for (let i = 1; i <= 5; i++) {
          const image = data[0][`box${i}`];
          const item = data[0][`item_box${i}`];

          if (image && item) {
            const productLink = `https://quirckart.in/category/${item.category_id}/subcategory/${item.subcategory_id}/product/${item.id}`;
            fetchedImages.push({
              id: i,
              src: image,
              alt: item.name || `Image ${i}`,
              link: productLink,
            });
          }
        }

        setImages(fetchedImages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex >= images.length - imagesPerView ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - imagesPerView : prevIndex - 1
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);

    return () => clearInterval(interval);
  }, [images, currentIndex, imagesPerView]);

  return (
    <div className="image-section">
      <button onClick={handlePrev} className="nav-button">‹</button>

      <div className="image-container" {...handlers}>
        {images.slice(currentIndex, currentIndex + imagesPerView).map((image) => (
          <div key={image.id} className="image-box">
            <img src={image.src} alt={image.alt} className="image" />
            <a href={image.link} target="_blank" rel="noopener noreferrer" className="shop-now-button">Shop Now</a>
          </div>
        ))}
      </div>

      <button onClick={handleNext} className="nav-button">›</button>
    </div>
  );
};

export default ImageSection;
