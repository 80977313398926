import React, { useState, useEffect } from 'react';
import './VideoGallery.css';

const VideoGallery = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch('https://quirckart.in/api/adnew/');
        const data = await response.json();

        // Extract video URLs and product links from the API response
        const videoData = [];
        data.forEach((item) => {
          for (let i = 1; i <= 6; i++) {
            const videoUrl = item[`video${i}`];
            const videoItem = item[`item_video${i}`];

            if (videoUrl && videoItem) {
              const productLink = `https://quirckart.in/category/${videoItem.category_id}/subcategory/${videoItem.subcategory_id}/product/${videoItem.id}`;
              videoData.push({
                id: videoItem.id,
                video_url: videoUrl,
                product_link: productLink,
                description: videoItem.name || `Product ${videoItem.id} Video`,
              });
            }
          }
        });

        setVideos(videoData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching videos:', error);
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="video-gallery">
      <h1 className="shop-the-reel-heading">
        Shop the <span>Reel</span>
      </h1>
      {loading ? (
        <p>Loading videos...</p>
      ) : (
        <div className="video-scroll-container">
          {videos.map((video) => (
            <div key={video.id} className="video-item">
              <video
                src={video.video_url}
                autoPlay
                loop
                muted
                playsInline
                className="video"
                aria-label={video.description}
              />
              <a
                href={video.product_link}
                className="shop-now-btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Shop Now
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VideoGallery;
