import React, { useState, useEffect, useRef } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Badge,
  Box,
  Popover,
  Button,
  Drawer,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import {
  Search as SearchIcon,
  ShoppingCart as ShoppingCartIcon,
  AccountCircle,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import UserDrawer from "../UserDrawer";
import { handleLogout } from "../../../utils";

// Styles for search component
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f1f3f6", // Keeping search box background unchanged
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#333333", // Updated text color
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    backgroundColor: "#000000000000",
    boxShadow: theme.shadows[4],
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#e0e0e0", // Light background for buttons
  color: "#333333", // Dark text color
  margin: theme.spacing(1, 0), // Margins for spacing
  width: "100%",
  "&:hover": {
    backgroundColor: "#bdbdbd", // Darker shade on hover
  },
}));

function UserNavbar() {
  const { baseUrl } = useSelector((state) => state.apiBaseUrl)
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const dropdownRef = useRef();
  const navigate = useNavigate();
  const { cartItems } = useSelector((state) => state.userDetailsData);
  const userAccessToken = sessionStorage.getItem('userAccessToken')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        fetchSearchResults(searchQuery);
      } else {
        setOpenDropdown(false);
        setSearchResults([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(false);
        setSearchQuery("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  const fetchSearchResults = async (query) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/items/search/?q=${query}`
      );
      setSearchResults(response?.data);
      setLoading(false);
      setOpenDropdown(true);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching search results:", error);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleCartClick = () => {
    navigate("/cart");
  };

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectSearchResult = (result) => {
    setSearchQuery("");
    setOpenDropdown(false);
    navigate(
      `category/${result.category_id}/subcategory/${result.subcategory_id}/product/${result.id}`
    );
  };

  return (
<AppBar
  position="static"
  style={{
    backgroundColor: "#ffffff", // Replace with the desired color
    borderBottom: "3px solid gold",
  }}
>
      <Toolbar className="flex justify-between">
        <div className="flex items-center">
          <IconButton
            edge="start"
            className="mr-2"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon style={{ color: "#000000"}} />
          </IconButton>
         
          {isSmallScreen ? (
            <img
              src="https://res.cloudinary.com/dv9iswy2f/image/upload/v1735856083/b6qqt02pvp8b4d4kbjva.webp"
              alt="Quirckart Small"
              className="small-icon-navbar"
              style={{ marginBottom: "0px",width:"24px"}}
              onClick={() => navigate("/")}
            />
          ) : (
            <img
              src="https://res.cloudinary.com/dv9iswy2f/image/upload/v1736164074/pr2fhxlqnvp28wvkc3ve.png"
              alt="Quirckart"
              className="modern-logo"
              style={{ marginBottom: "0px" , cursor: "pointer"}}
              onClick={() => navigate("/")}
            />
          )}
          
        </div>

        <Search style={{ color: "#333333", width: "60%", maxWidth: "600px" }} ref={dropdownRef}>
          <SearchIconWrapper>
            <SearchIcon style={{ color: "#333333" }} />
          </SearchIconWrapper>
          <StyledInputBase
            style={{ color: "#333333", width: "100%" }}
            placeholder="Search for products"
            inputProps={{ "aria-label": "search" }}
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          {openDropdown && (
            <Box
              className="absolute w-full shadow-lg rounded z-50"
              style={{ backgroundColor: "#dcdcdc", color: "#333333", marginTop: "0rem" }} // Lighter gray background for dropdown
            >
              {loading ? (
                <Box className="p-4 flex justify-center">
                  <CircularProgress size={24} style={{ color: "#333333" }} />
                </Box>
              ) : searchResults?.length > 0 ? (
                <List>
                  {searchResults?.map((result) => (
                    <ListItem
                      key={result.id}
                      button
                      onClick={() => handleSelectSearchResult(result)}
                    >
                      <ListItemText
                        primary={result.name}
                        secondary={`in ${result.subcategory}`}
                        style={{ color: "#333333" }} // Black text
                        className="text-sm md:text-md"
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box className="p-4 text-center" style={{ color: "#333333" }}>
                  No results found
                </Box>
              )}
            </Box>
          )}
        </Search>

        <Box className="flex items-center">
          <IconButton
            aria-label="show cart items"
            color="inherit"
            onClick={handleCartClick}
          >
            <Badge badgeContent={cartItems?.length} style={{ color: "#000000" }}>
              <ShoppingCartIcon style={{ color: "#000000" }} />
            </Badge>
          </IconButton>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={handleProfileClick}
            style={{ color: "#000000" }} // Profile button color
          >
            <AccountCircle style={{ color: "#000000" }} />
          </IconButton>
        </Box>
      </Toolbar>

      <UserDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />

      <StyledPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* <StyledButton onClick={() => navigate("/profile")}>Profile</StyledButton> */}
        {
          userAccessToken ?
            <StyledButton onClick={() => handleLogout(navigate)}>Logout</StyledButton> :

            <StyledButton onClick={() => navigate("/login")}>Login</StyledButton>
        }
      </StyledPopover>
    </AppBar>
  );
}

export default UserNavbar;
