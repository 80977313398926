import React, { useState } from "react";
import { Box, Typography, IconButton, Grid, Divider, Modal, Fade } from "@mui/material";
import { Facebook, Twitter, Instagram, YouTube, Close } from "@mui/icons-material";
import { termsAndConditions, cancellationpolicy, privacyPolicy, shippingPolicy } from "../../../utils/policyUtils";

const UserFooter = () => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const handleOpenModal = (title, content) => {
    setModalTitle(title);
    setModalContent(content);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <Box component="footer" sx={{
      backgroundColor: "#121212", // Dark background for the footer
      color: "white",
      py: 6,
      px: 8,
      fontFamily: '"Poppins", sans-serif',
      borderTop: "3px solid #00C6FF", // Neon blue accent border
    }}>
      <Grid container spacing={5}>
        {/* Customer Service Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 600, color: "#00C6FF", fontFamily: '"Roboto", sans-serif' }}>
            Customer Service
          </Typography>
          <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            <li>
              <a href="#" style={{
                color: "#B0B0B0", textDecoration: "none", transition: "all 0.3s ease",
                "&:hover": { color: "#00C6FF", transform: "scale(1.05)" }
              }}>
                Contact Us: info@quirckart.com
              </a>
            </li>
            <li>
              <a href="#" style={{
                color: "#B0B0B0", textDecoration: "none", transition: "all 0.3s ease",
                "&:hover": { color: "#00C6FF", transform: "scale(1.05)" }
              }}>
                FAQs
              </a>
            </li>
            <li>
              <a href="#" style={{
                color: "#B0B0B0", textDecoration: "none", transition: "all 0.3s ease",
                "&:hover": { color: "#00C6FF", transform: "scale(1.05)" }
              }}>
                Returns and Exchange
              </a>
            </li>
          </ul>
        </Grid>

        {/* Company Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 600, color: "#00C6FF", fontFamily: '"Roboto", sans-serif' }}>
            Company
          </Typography>
          <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            <li>
              <a href="#" style={{
                color: "#B0B0B0", textDecoration: "none", transition: "all 0.3s ease",
                "&:hover": { color: "#00C6FF", transform: "scale(1.05)" }
              }}>
                About Us
              </a>
            </li>
            <li>
              <a href="#" style={{
                color: "#B0B0B0", textDecoration: "none", transition: "all 0.3s ease",
                "&:hover": { color: "#00C6FF", transform: "scale(1.05)" }
              }}>
                Careers
              </a>
            </li>
            <li>
              <a href="#" style={{
                color: "#B0B0B0", textDecoration: "none", transition: "all 0.3s ease",
                "&:hover": { color: "#00C6FF", transform: "scale(1.05)" }
              }}>
                Press
              </a>
            </li>
          </ul>
        </Grid>

        {/* Company Policies Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 600, color: "#00C6FF", fontFamily: '"Roboto", sans-serif' }}>
            Company Policies
          </Typography>
          <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            <li>
              <a href="#" onClick={() => handleOpenModal("Terms and Conditions", termsAndConditions)} style={{
                color: "#B0B0B0", textDecoration: "none", transition: "all 0.3s ease",
                "&:hover": { color: "#00C6FF", transform: "scale(1.05)" }
              }}>
                Terms and Conditions
              </a>
            </li>
            <li>
              <a href="#" onClick={() => handleOpenModal("Shipping Policy", shippingPolicy)} style={{
                color: "#B0B0B0", textDecoration: "none", transition: "all 0.3s ease",
                "&:hover": { color: "#00C6FF", transform: "scale(1.05)" }
              }}>
                Shipping Policy
              </a>
            </li>
            <li>
              <a href="#" onClick={() => handleOpenModal("Privacy Policy", privacyPolicy)} style={{
                color: "#B0B0B0", textDecoration: "none", transition: "all 0.3s ease",
                "&:hover": { color: "#00C6FF", transform: "scale(1.05)" }
              }}>
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="#" onClick={() => handleOpenModal("Cancellation Policy", cancellationpolicy)} style={{
                color: "#B0B0B0", textDecoration: "none", transition: "all 0.3s ease",
                "&:hover": { color: "#00C6FF", transform: "scale(1.05)" }
              }}>
                Cancellation Policy
              </a>
            </li>
          </ul>
        </Grid>

        {/* Follow Us Section */}
        <Grid item xs={12} sm={6} md={3} sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 600, color: "#00C6FF", fontFamily: '"Roboto", sans-serif' }}>
            Follow Us
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <IconButton
              href="https://www.facebook.com/QuircKart" target="_blank"
              sx={{
                color: "#3b5998", transform: "scale(1.1)", boxShadow: "0 0 10px 2px #3b5998", transition: "transform 0.3s ease, box-shadow 0.3s ease"

              }}>
              <Facebook />
            </IconButton>
            <IconButton
              href="#"
              sx={{

                color: "#1DA1F2", transform: "scale(1.1)", boxShadow: "0 0 10px 2px #1DA1F2", transition: "transform 0.3s ease, box-shadow 0.3s ease"

              }}>
              <Twitter />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/quirckart_/" target="_blank"
              sx={{

                background: "linear-gradient(45deg, #D13584, #F560AA)", transform: "scale(1.1)", boxShadow: "0 0 10px 2px #D13584", transition: "transform 0.3s ease, box-shadow 0.3s ease"

              }}>
              <Instagram />
            </IconButton>
            <IconButton
              href="#"
              sx={{

                color: "#FF0000", transform: "scale(1.1)", boxShadow: "0 0 10px 2px #FF0000", transition: "transform 0.3s ease, box-shadow 0.3s ease"

              }}>
              <YouTube />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4, backgroundColor: "#444", borderBottom: "1px solid #333" }} />

      {/* Copyright Section */}
      <Typography variant="body2" sx={{ fontSize: "18px", textAlign: "center", color: "#fff" }}>
        QUIRCKART INFOTECH PRIVATE LIMITED

      </Typography>
      <Typography variant="body2" sx={{ fontSize: "14px", textAlign: "center", color: "#fff" }}>
        Jaksons Crown Heights Unit No.101A 1st Floor 1st Floor New Delhi

      </Typography>
      <Typography variant="body2" sx={{ textAlign: "center", color: "#BBB" }}>
        © 2024 Quirckart. All Rights Reserved.
      </Typography>

      {/* Modal Popup for Terms, Privacy, etc. */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.7)" } }}
      >
        <Fade in={open}>
          <Box sx={{
            position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
            backgroundColor: "white", padding: 3, borderRadius: "8px", width: "60%", maxWidth: "600px",
            maxHeight: "80%", overflowY: "auto"
          }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute", top: 10, right: 10, color: "#F0A500"
              }}
            >
              <Close />
            </IconButton>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              {modalTitle}
            </Typography>
            <Typography sx={{ fontSize: "14px", lineHeight: "1.8" }}>
              {modalContent}
            </Typography>
          </Box>
        </Fade>
      </Modal>

    </Box>
  );
};

export default UserFooter;
