import React, { useEffect, useState } from "react";
import {
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Slider,
  Box,
  Button,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";

const SidebarContainer = styled(Box)({
  width: "250px",
  backgroundColor: "#f4f4f4",
  padding: "1rem",
  borderRight: "1px solid #ddd",
});

const FilterSidebar = ({ onApplyFilters, setFilteredProducts, allProducts }) => {
  const maxPrice = Math.max(...allProducts.map((product) => product.price)); // Get max price dynamically
  const [priceRange, setPriceRange] = useState([0, maxPrice]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filters, setFilters] = useState({
    priceRange: [0, maxPrice],
    rating: null, // Single selection for rating
    categories: [],
    brands: [],
  });

  const isDesktop = useMediaQuery("(min-width:960px)");

  useEffect(() => {
    setPriceRange([0, maxPrice]); // Initialize price range
    setFilters({ priceRange: [0, maxPrice], rating: null });
  }, [allProducts, maxPrice]);

  const fetchFilteredData = () => {
    console.log("Filters applied:", filters);
    if (onApplyFilters) {
      onApplyFilters(filters); // Pass filters to the parent
    }
  };

  const handlePriceChange = (event, newValue) => {
    setPriceRange([Math.ceil(newValue[0]), Math.ceil(newValue[1])]);
    setFilters((prev) => ({ ...prev, priceRange: [Math.ceil(newValue[0]), Math.ceil(newValue[1])] }));
  };

  const handleRatingChange = (value) => {
    setFilters((prev) => ({
      ...prev,
      rating: prev.rating === value ? null : value,
    }));
  };

  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };


  const resetFilters = () => {
    setFilteredProducts(allProducts); // Reset product list
    setPriceRange([0, maxPrice]); // Reset price range
    setFilters({
      priceRange: [0, maxPrice],
      rating: null, // Reset rating
    });
  };



  return (
    <>
      {/* Button for mobile/tablet view */}
      <Button
        variant="contained"
        onClick={() => toggleDrawer(true)}
        sx={{
          display: { xs: "block", md: "none" },
          position: "relative",
          top: "10px",
          left: "10px",
          width: "max-content",
        }}
      >
        Filters
      </Button>

      {/* Drawer for mobile/tablet view */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        PaperProps={{ style: { width: "250px" } }}
      >
        <SidebarContainer>
          <Typography variant="h6" gutterBottom>
            Filters
          </Typography>

      
          {/* Price Range */}
          <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Price Range
            </Typography>
            <Slider
              value={priceRange}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              min={0}
              max={Math.ceil(maxPrice)}
              step={1}
            />
            <Box className="flex justify-between text-sm">
              <Typography>₹{Math.ceil(priceRange[0])}</Typography>
              <Typography>₹{Math.ceil(priceRange[1])}</Typography>
            </Box>
          </FormControl>

          {/* Ratings */}
          <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Ratings
            </Typography>
            {["1 Star & Up", "2 Stars & Up", "3 Stars & Up", "4 Stars & Up", "5 Stars"].map(
              (rating, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={filters.rating === rating}
                      onChange={() => handleRatingChange(rating)}
                    />
                  }
                  label={rating}
                />
              )
            )}
          </FormControl>

          {/* Brands */}
          {/* <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Brand
            </Typography>
            {["Brand A", "Brand B", "Brand C"].map((brand) => (
              <FormControlLabel
                key={brand}
                control={
                  <Checkbox
                    onChange={() => handleCheckboxChange("brands", brand)}
                  />
                }
                label={brand}
              />
            ))}
          </FormControl> */}


          <Button
            variant="outlined"
            onClick={resetFilters}

            sx={{ marginTop: 2, width: "100%" }}
          >
            Reset Filters
          </Button>


          {/* Apply Filters Button for Mobile */}
          <Button
            variant="contained"
            onClick={() => {
              fetchFilteredData(); // API call for mobile
              toggleDrawer(false);
            }}
            sx={{ width: "100%", mt: 2, display: { xs: "block", md: "none" } }}
          >
            Apply Filters
          </Button>


        </SidebarContainer>
      </Drawer>

      {/* Sidebar for desktop view */}
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <SidebarContainer>
          <Typography variant="h6" gutterBottom>
            Filters
          </Typography>

        
          {/* Price Range */}
          <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Price Range
            </Typography>
            <Slider
              value={priceRange}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              min={0}
              max={Math.ceil(maxPrice)}
              step={1}
            />
            <Box className="flex justify-between text-sm">
              <Typography>₹{Math.ceil(priceRange[0])}</Typography>
              <Typography>₹{Math.ceil(priceRange[1])}</Typography>
            </Box>
          </FormControl>

          {/* Ratings */}
          <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Ratings
            </Typography>
            {["1 Star & Up", "2 Stars & Up", "3 Stars & Up", "4 Stars & Up", "5 Stars"].map(
              (rating, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={filters.rating === rating}
                      onChange={() => handleRatingChange(rating)}
                    />
                  }
                  label={rating}
                />
              )
            )}
          </FormControl>

          {/* Brands */}
          {/* <FormControl component="fieldset">
            <Typography variant="subtitle1" gutterBottom>
              Brand
            </Typography>
            {["Brand A", "Brand B", "Brand C"].map((brand) => (
              <FormControlLabel
                key={brand}
                control={
                  <Checkbox
                    onChange={() => handleCheckboxChange("brands", brand)}
                  />
                }
                label={brand}
              />
            ))}
          </FormControl> */}

          <Button
            variant="outlined"
            onClick={resetFilters}

            sx={{ marginTop: 2, width: "100%" }}
          >
            Reset Filters
          </Button>


          {/* Apply Filters Button for Mobile */}
          <Button
            variant="contained"
            onClick={() => {
              fetchFilteredData(); // API call for mobile
            }}
            sx={{ width: "100%", mt: 2}}
          >
            Apply Filters
          </Button>

        </SidebarContainer>
      </Box>
    </>
  );
};

export default FilterSidebar;
