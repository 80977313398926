import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumbs as MUIBreadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';
import { useSelector } from 'react-redux';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = useMemo(() => location.pathname.split('/').filter((x) => x), [location.pathname]);
  const [breadcrumbData, setBreadcrumbData] = useState([]);
  const { baseUrl } = useSelector((state) => state.apiBaseUrl);

  useEffect(() => {
    const fetchBreadcrumbData = async () => {
      const data = [];

      for (let i = 0; i < pathnames.length; i++) {
        const segment = pathnames[i];
        let name = '';
        let link = '';

        try {
          if (i === 0 && segment === 'category' && pathnames[i + 1]) {
            // Fetch category name
            const response = await axios.get(`${baseUrl}/api/categories/${pathnames[i + 1]}/`);
            name = response.data.name;
            link = `/category/${pathnames[i + 1]}`;
          } else if (i === 2 && segment === 'subcategory' && pathnames[i + 1]) {
            // Fetch subcategory name
            const response = await axios.get(`${baseUrl}/api/subcategories/${pathnames[i + 1]}/`);
            name = response.data.name;
            link = `/category/${pathnames[i - 1]}/subcategory/${pathnames[i + 1]}`;
          } else if (i === 4 && segment === 'product' && pathnames[i + 1]) {
            // Fetch product name
            const response = await axios.get(`${baseUrl}/api/products/${pathnames[i + 1]}/`);
            name = response.data.title;
            link = `/category/${pathnames[i - 3]}/subcategory/${pathnames[i - 1]}/product/${pathnames[i + 1]}`;
          }
        } catch (error) {
          console.error(`Error fetching name for segment: ${segment}`, error);
        }

        if (name && link) {
          data.push({ name, link });
        }
      }

      setBreadcrumbData(data);
    };

    fetchBreadcrumbData();
  }, [pathnames, baseUrl]);

  return (
    <div className="px-4 py-2 bg-gray-50">
      <MUIBreadcrumbs 
        separator={<NavigateNextIcon fontSize="small" />} 
        aria-label="breadcrumb"
      >
        <Link
          to="/"
          className="flex items-center text-gray-600 hover:text-blue-600"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Link>
        
        {breadcrumbData.map((item, index) => {
          const isLast = index === breadcrumbData.length - 1;

          return isLast ? (
            <Typography 
              key={item.link} 
              color="text.primary"
              className="text-gray-800"
            >
              {item.name}
            </Typography>
          ) : (
            <Link
              key={item.link}
              to={item.link}
              className="text-gray-600 hover:text-blue-600"
            >
              {item.name}
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;
