import { createSlice } from "@reduxjs/toolkit";

const initialState={
    // baseUrl: window.location.origin,
    // baseUrl: "http://foodporium-env.eba-wnzyt2im.eu-north-1.elasticbeanstalk.com",
    baseUrl: "https://quirckart.in",
}

const ApiBaseUrlSlice= createSlice({
    name:"ApiBaseUrl",
    initialState,
    reducers:{
        setApiBaseUrl:(state,action)=>{
            state.baseUrl=action.payload;
        }
    }
});

export const{
    setApiBaseUrl
}=ApiBaseUrlSlice.actions;
export default ApiBaseUrlSlice.reducer;