import React from "react";
import "./ScrollingBanner.css"; // Import the CSS

const ScrollingBanner = () => {
  return (
    <div className="scrolling-banner">
      <div className="">
        Your One Stop Store
      </div>
    </div>
  );
};

export default ScrollingBanner;
