import "./App.css";
import Routes from "./router";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
// import store from "./store";
import store from "./store/store";

import Snowfall from "./Snowfall";

function App() {

  return (
    <div className="App">
      {/* <Snowfall /> */}
      <Provider store={store}>
        <Toaster />
        <Routes />
      </Provider>
    </div>
  );
}

export default App;
