import { Box, Button, Divider, List, ListItem, ListItemText, Radio, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAddresses } from "../../../../store/slices/UserDetailsSlice";
import { setLoader } from "../../../../store/slices/UserJourneyDataSlice";

function UserAddress({ setIsZipCodeServicable, isZipCodeServicable, getAddressDataFromChild }) {
  // const addresses = useSelector((state) => state.addresses || []);

  const dispatch = useDispatch();
  const { baseUrl } = useSelector((state) => state.apiBaseUrl)
  const userSession = JSON.parse(sessionStorage.getItem('userSession'))

  const defaultNameAndPhone = {
    name: "",
    phone: ""
  }

  const [nameAndPhone, setNameAndPhone] = useState({
    name: "",
    phone: ""
  })

  const defaultUserInfo = {
    // name: "",
    // phone: "",
    address_line1: "",
    city: "",
    state: "",
    postal_code: "",
    country: "India",
  };

  const [userInfo, setUserInfo] = useState({
    // name: "",
    // phone: "",
    address_line1: "",
    city: "",
    state: "",
    postal_code: "",
    country: "India"
  });

  const [errors, setErrors] = useState({});
  const [zipCheckMessage, setZipCheckMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAddressSaved, setIsAddressSaved] = useState(false);
  const [isAddNewAddressTrue, setIsAddNewAddressTrue] = useState(false);
  const [isEditAddressTrue, setIsEditAddressTrue] = useState(false);
  const [addresses, setAddresses] = useState([])
  const [basicDetails, setBasicDetails] = useState({})
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressErrors, setAddressErrors] = useState({});



  useEffect(() => {
    fetchUserAddresses();
  }, [])

  useEffect(() => {
    console.log("Addresses updated in Redux:", addresses);
  }, [addresses]);

  const fetchUserAddresses = async () => {
    try {
      console.log("trying to save")
      const response = await axios.post(`${baseUrl}/api/customer-search-create/`, {

        username: userSession?.user?.phoneNumber,
        password: userSession?.user?.phoneNumber
      })
      let createData = response?.data
      console.log('user details', response?.data)
      try {

        const response = await axios.get(`${baseUrl}/api/customers/${createData?.customer_id}/`)

        const data = response?.data;
        console.log('user details', data)

        setBasicDetails((prev) => ({
          ...prev,
          name: data.name || "",
          phone: data.phone || ""
        }))
        setAddresses(data.addresses)
        // dispatch(setAddresses(data.addresses))
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log("error while getting user category data", error?.response?.message)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name == "name" || name == "phone") {
      setNameAndPhone((prev) => ({
        ...prev,
        [name]: value
      }))
    }
    else {
      setUserInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value
      }));
    }
    setIsAddressSaved(false); // Hide edit button on any input change
  };

  const validateInputs = () => {
    let tempErrors = {};
    if (!nameAndPhone.name) tempErrors.name = "Name is required";
    if (!nameAndPhone.phone) tempErrors.phone = "Phone number is required";
    else if (!/^\d{10}$/.test(nameAndPhone.phone))
      tempErrors.phone = "Phone number is not valid";
    if (!userInfo.address_line1) tempErrors.address_line1 = "Street address is required";
    if (!userInfo.city) tempErrors.city = "City is required";
    if (!userInfo.state) tempErrors.state = "State is required";
    if (!userInfo.postal_code) tempErrors.postal_code = "Zip code is required";
    else if (!/^\d{6}$/.test(userInfo.postal_code))
      tempErrors.postal_code = "Zip code is not valid";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleZipCodeCheck = async (zip) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://quirckart.in/fetch-pin-code/` //production

        // `http://staging-express.delhivery.com/c/api/pin-codes/json/` //staging

        , {
          params: {
            token:
              // "0368e03c66b1fc26848d7d4bed4798c45de2b3cf" //staging
              "454efe120c467091556c613bada8af5b3bab3bc5"  //production
            ,
            filter_code: zip
          },
          headers: {
            Accept: "application/json",
            Cookie: "sessionid=l78dei4ygp4dc26fou7roy2bfebodop8"
          }
        });

      const data = response.data;
      console.log('data response from the api is...', data)
      if (data.delivery_codes && data.delivery_codes[0]?.postal_code?.pin == zip) {
        setZipCheckMessage("This zip code is serviceable.");
        setIsZipCodeServicable(true);
      } else {
        setZipCheckMessage("This zip code is not serviceable.");
        setIsZipCodeServicable(false);
      }
    } catch (error) {
      setZipCheckMessage("Error checking zip code serviceability.");
      setIsZipCodeServicable(false);
    } finally {
      setLoading(false);
    }
  };


  const handleZipCodeCheckForAddressSelection = async (zip) => {
    dispatch(setLoader(true));
    try {
      const response =
        await axios.get(

          `https://quirckart.in/fetch-pin-code/` //production

          // `http://staging-express.delhivery.com/c/api/pin-codes/json/` //staging


          , {
            params: {

              token:
                // "0368e03c66b1fc26848d7d4bed4798c45de2b3cf" //staging
                "454efe120c467091556c613bada8af5b3bab3bc5"  //production
              ,
              filter_code: zip,
            },
          });
      const data = response.data;
      return data.delivery_codes && data.delivery_codes[0]?.postal_code?.pin == zip;
    } catch (error) {
      console.error("Error checking zip code:", error);
      return false;
    }
    finally {
      dispatch(setLoader(false))
    }
  };


  const handleZipCodeChange = (e) => {
    const zip = e.target.value;
    if (/^\d{0,6}$/.test(zip)) {
      setUserInfo((prevInfo) => ({ ...prevInfo, postal_code: zip }))
      setZipCheckMessage("");
      setIsZipCodeServicable(false);

      // Call the API if the zip code reaches exactly 6 digits
      if (zip.length === 6) {

        handleZipCodeCheck(zip);
      }
    }
  };


  const handleSaveAddress = () => {
    if (validateInputs()
      // && isZipCodeServicable
    ) {
      console.log("save button is working");
      sessionStorage.setItem("billUserAddress", JSON.stringify(userInfo));

      // Update flags and call backend
      saveAddressInBackend();
    } else {
      console.warn("Address validation failed or ZIP code is not serviceable.");
    }
  };


  const saveAddressInBackend = async () => {
    try {
      console.log("trying to save");

      // Step 1: Retrieve/Create customer data
      const response = await axios.post(`${baseUrl}/api/customer-search-create/`, {
        username: userSession?.user?.phoneNumber,
        password: userSession?.user?.phoneNumber,
      });
      let createData = response?.data;
      console.log("user details", response?.data);

      // Step 2: Prepare updated addresses
      let updatedAddresses;
      if (isAddNewAddressTrue) {
        // Adding a new address
        console.log("new address is trying to saving")
        updatedAddresses = [...addresses, userInfo];
        console.log(updatedAddresses, "updatedAddress")
      } else if (isEditAddressTrue) {
        // Editing an existing address
        console.log("edit address is trying to saving")
        updatedAddresses = addresses.map((address, index) =>
          index === selectedAddress ? userInfo : address
        );
      } else {
        console.error("No action detected for address saving");
        return;
      }

      // Step 3: Call PATCH API to save addresses
      const patchResponse = await axios.patch(
        `${baseUrl}/api/customers/${createData?.customer_id}/`,
        {
          name: nameAndPhone.name,
          phone: nameAndPhone.phone,
          addresses: updatedAddresses,
        }
      );
      console.log("Address successfully saved:", patchResponse?.data);

      // Step 4: Update state and UI
      setAddresses(updatedAddresses); // Update addresses in local state
      setBasicDetails(nameAndPhone)
      setIsAddressSaved(true); // Show success message
      setIsEditAddressTrue(false); // Exit edit mode
      setIsAddNewAddressTrue(false); // Exit add mode

    } catch (error) {
      console.error("Error while saving address:", error);
    }
  };




  const handleRadioChange = (index) => {
    setSelectedAddress(index);
    setUserInfo(defaultUserInfo)
    setNameAndPhone(defaultNameAndPhone)
    setIsEditAddressTrue(false)
    setIsAddNewAddressTrue(false)
  };


  const handleAddAddress = () => {
    setIsAddNewAddressTrue(true);
    setUserInfo(defaultUserInfo)
    setNameAndPhone(defaultNameAndPhone)
  }

  const handleEdit = (index) => {
    setUserInfo(addresses[index]); // Load selected address into userInfo
    setNameAndPhone(basicDetails)
    setSelectedAddress(index); // Track which address is being edited
    setIsEditAddressTrue(true); // Enable edit mode
    setIsAddNewAddressTrue(false); // Ensure "add new" mode is disabled
  };


  const handleDeliver = async (index) => {
    const address = addresses[index];
    const isServiceable = await handleZipCodeCheckForAddressSelection(address.postal_code);

    if (isServiceable) {
      setIsZipCodeServicable(true);
      getAddressDataFromChild(address, basicDetails); // Send the address data back to the parent

    } else {
      setIsZipCodeServicable(false);
      setAddressErrors((prev) => ({
        ...prev,
        [index]: "This zip code is not deliverable. Please select or update the address.",
      }));
    }
  };

  const handleCancelAddress = () => {
    setIsAddNewAddressTrue(false);
    setIsEditAddressTrue(false);
    setUserInfo(defaultUserInfo)
    setNameAndPhone(defaultNameAndPhone)
  }

  return (
    <Box mt={4}>
      {addresses.length > 0 ? (
        <List>
          {addresses.map((address, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <Radio
                  checked={selectedAddress === index}
                  onChange={() => handleRadioChange(index)}
                  value={index}
                />
                <ListItemText
                  primary={`${basicDetails.name} - ${basicDetails.phone}`}
                  secondary={`${address.address_line1}, ${address.city}, ${address.state} - ${address.postal_code}`}
                />
                {selectedAddress === index && (
                  <button
                    style={{
                      marginLeft: "auto",
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "none",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEdit(index)}
                  >
                    Edit
                  </button>
                )}
              </ListItem>
              {selectedAddress === index && (
                <div style={{ padding: "10px", textAlign: "center" }}>
                  <button
                    style={{
                      backgroundColor: "#28a745",
                      color: "white",
                      border: "none",
                      padding: "10px 20px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDeliver(index)}
                  >
                    Deliver to This Address
                  </button>
                </div>
              )}

              {selectedAddress === index && addressErrors[index] && (
                <Typography
                  color="error"
                  style={{ paddingLeft: "40px", marginTop: "5px" }}
                >
                  {addressErrors[index]}
                </Typography>
              )}

              <Divider />
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Typography>No addresses found. Please add a new address.</Typography>
      )}


      {!isEditAddressTrue &&
        (
          <Button
            sx={{ marginBottom: "1em" }}
            variant="contained"
            onClick={handleAddAddress}
          >
            Add New Address
          </Button>
        )
      }


      {(isAddNewAddressTrue || isEditAddressTrue)
        ?

        <div>
          <TextField
            fullWidth
            label="Name"
            variant="outlined"
            name="name"
            value={nameAndPhone.name}
            onChange={handleInputChange}
            error={!!errors.name}
            helperText={errors.name}
            sx={{ marginBottom: ".5rem" }}
          // Disable if address is saved and not in edit mode
          />
          <TextField
            fullWidth
            label="Phone Number"
            variant="outlined"
            name="phone"
            value={nameAndPhone.phone}
            onChange={handleInputChange}
            error={!!errors.phone}
            helperText={errors.phone}
            sx={{ marginBottom: ".5rem" }}

          />
          <TextField
            fullWidth
            label="Street Address"
            variant="outlined"
            name="address_line1"
            value={userInfo.address_line1}
            onChange={handleInputChange}
            error={!!errors.address_line1}
            helperText={errors.address_line1}
            sx={{ marginBottom: ".5rem" }}

          />
          <TextField
            fullWidth
            label="City"
            variant="outlined"
            name="city"
            value={userInfo.city}
            onChange={handleInputChange}
            error={!!errors.city}
            helperText={errors.city}
            sx={{ marginBottom: ".5rem" }}

          />
          <TextField
            fullWidth
            label="State"
            variant="outlined"
            name="state"
            value={userInfo.state}
            onChange={handleInputChange}
            error={!!errors.state}
            helperText={errors.state}
            sx={{ marginBottom: ".5rem" }}

          />
          <TextField
            fullWidth
            label="Country"
            variant="outlined"
            name="country"
            value={"India"}
            onChange={handleInputChange}
            error={!!errors.country}
            helperText={errors.country}
            sx={{ marginBottom: ".5rem" }}
            disabled={true}
          />
          <TextField
            fullWidth
            label="Zip Code"
            variant="outlined"
            name="postal_code"
            value={userInfo.postal_code}
            onChange={handleZipCodeChange}
            error={!!errors.postal_code}
            helperText={errors.postal_code || zipCheckMessage}
            sx={{ marginBottom: ".5rem" }}

          />
        </div>
        : null
      }


      {


        (isAddNewAddressTrue || isEditAddressTrue) && (
          <>
            <Button
              // variant="contained"
              color="primary"
              onClick={handleCancelAddress}
              // disabled={loading}
              sx={{ marginRight: "1rem" }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveAddress}
              disabled={loading}
              sx={{ marginRight: "1rem" }}
            >
              {loading ? "Checking..." : "Save Address"}
            </Button>
          </>
        )
      }



    </Box >
  );
}

export default UserAddress;
