
import { Navigate, useNavigate } from 'react-router-dom';
import { addToCart, updateCartQuantity, removeFromCart } from '../store/slices/UserDetailsSlice';
import { toastSuccess } from './toastUtil';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getBaseUrl } from './setBaseUrl';


const userAccessToken = sessionStorage.getItem('userAccessToken')
const userSession = JSON.parse(sessionStorage.getItem('userSession'))
const baseUrl = getBaseUrl()
// const { baseUrl } = useSelector((state) => state.apiBaseUrl)


export const setUserData = async (updatedCart) => {
 
  try {
    const response = await axios.post(`${baseUrl}/api/customer-search-create/`, {

      username: userSession?.user?.phoneNumber,
      password: userSession?.user?.phoneNumber
    })
    let createData = response?.data
    console.log('user details', response?.data)
    try {
      const alteredCartItems = updatedCart.map(({ id, name, quantity }) => ({ item:id, item_name:name, quantity:quantity }))
      console.log(alteredCartItems,"alteredcartitems")
      const response = await axios.patch(`${baseUrl}/api/customers/${createData?.customer_id}/`
        ,
        {
          "cart_items": alteredCartItems
        }
      )

      // const data = response?.data;
      // sessionStorage.setItem("userDetailsFetch", JSON.stringify(data))
      // sessionStorage.setItem("cartItems", JSON.stringify(data?.cart_items_detail))
      // dispatch(resetCart(data?.cart_items_detail))
    } catch (error) {
      console.log(error);
    }
    // dispatch( setCategoryData(response?.data))
  } catch (error) {
    console.log("error while getting user category data", error?.response?.message)
  }
}

// Add or update the cart item
export const handleAddToCart = (product, dispatch, quantity, cartItems) => {

  console.log("product coming to add to cart", product, quantity)
  const cartItemsInLocalStorage = JSON.parse(localStorage.getItem("cartItems")) || [];
  const existingProduct = cartItemsInLocalStorage.find(item => item.id === product.id);
  // console.log("item found in cart")
  if (existingProduct) {
    // Increase quantity if product already exists
    const updatedCart = cartItemsInLocalStorage.map(item =>
      item.id === product.id ? { ...item, quantity: item.quantity + (quantity ? quantity : 1) } : item
    );
    console.log('updated cart is: ', updatedCart)
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCart));

    if (userAccessToken) {
      setUserData(updatedCart)
        .then(() => {
          console.log("User data successfully updated.");
        })
        .catch((error) => {
          console.error("Failed to update user data:", error);
        });
    }

    dispatch(updateCartQuantity({ id: product.id, quantity: existingProduct.quantity + (quantity ? quantity : 1) }));
  } else {
    // Add new product to cart with quantity 1
    const updatedCart = [...cartItemsInLocalStorage, { ...product, quantity: quantity ? quantity : 1 }];
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCart));

    if (userAccessToken) {
      setUserData(updatedCart)
        .then(() => {
          console.log("User data successfully updated.");
        })
        .catch((error) => {
          console.error("Failed to update user data:", error);
        });
    }

    dispatch(addToCart({ ...product, quantity: quantity ? quantity : 1 }));
  }
  toastSuccess("Item added to cart");
};

// Increase the quantity of an item
export const increaseCartItemQuantity = (productId, dispatch) => {
  const cartItemsInLocalStorage = JSON.parse(localStorage.getItem("cartItems")) || [];
  const updatedCart = cartItemsInLocalStorage.map(item =>
    item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
  );
  localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  sessionStorage.setItem("cartItems", JSON.stringify(updatedCart));

  if (userAccessToken) {
    setUserData(updatedCart)
      .then(() => {
        console.log("User data successfully updated.");
      })
      .catch((error) => {
        console.error("Failed to update user data:", error);
      });
  }


  dispatch(updateCartQuantity({ id: productId, quantity: updatedCart.find(item => item.id === productId).quantity }));
};

// Decrease the quantity of an item (remove if quantity is 1)
export const decreaseCartItemQuantity = (productId, dispatch) => {
  const cartItemsInLocalStorage = JSON.parse(localStorage.getItem("cartItems")) || [];
  const existingProduct = cartItemsInLocalStorage.find(item => item.id === productId);

  if (existingProduct.quantity > 1) {
    // Decrease quantity
    const updatedCart = cartItemsInLocalStorage.map(item =>
      item.id === productId ? { ...item, quantity: item.quantity - 1 } : item
    );
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCart));

    if (userAccessToken) {
      setUserData(updatedCart)
        .then(() => {
          console.log("User data successfully updated.");
        })
        .catch((error) => {
          console.error("Failed to update user data:", error);
        });
    }

    dispatch(updateCartQuantity({ id: productId, quantity: existingProduct.quantity - 1 }));
  } else {
    // If quantity is 1, remove the item
    removeCartItem(productId, dispatch);
  }
};

// Remove an item from the cart
export const removeCartItem = (productId, dispatch) => {
  const cartItemsInLocalStorage = JSON.parse(localStorage.getItem("cartItems")) || [];
  const updatedCart = cartItemsInLocalStorage.filter(item => item.id !== productId);
  localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  sessionStorage.setItem("cartItems", JSON.stringify(updatedCart));

  if (userAccessToken) {
    setUserData(updatedCart)
      .then(() => {
        console.log("User data successfully updated.");
      })
      .catch((error) => {
        console.error("Failed to update user data:", error);
      });
  }

  dispatch(removeFromCart(productId));
  toastSuccess("Item removed from cart");
};


export const logoutAffiliate = (navigate) => {
  sessionStorage.removeItem('affiliateAccess');
  sessionStorage.removeItem('affiliateRefresh')
  sessionStorage.removeItem('affiliate_username')
  navigate('/affiliate')
}