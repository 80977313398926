import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import UserRoute from './UserRoute';
import AdminRoute from "./AdminRoute";
import HomePage from "../pages/HomePage";
import AdminSignupLogin from "../pages/AdminSignupLogin";
import AdminDashboard from "../pages/AdminDashboard";
import NotFound from "../pages/NotFound";
import Categories from "../components/Admin/AdminSections/Categories";
import SubCategories from "../components/Admin/AdminSections/SubCategories";
import Orders from "../components/Admin/AdminSections/Orders";
import AddAdvertise from "../components/Admin/AdminSections/AddAdvertise";
import Products from "../components/Admin/AdminSections/Products";
import ChangeWebInfo from "../components/Admin/AdminSections/ChangeWebInfo";
import AdminLayout from "../components/Admin/AdminLayout";
import UserLayout from "../components/User/UserLayout";
import UserCart from "../components/User/UserPages/UserCart";
import UserCategory from "../components/User/UserPages/UserCategory";
import UserSubCategory from "../components/User/UserPages/UserSubCategory";
import UserProduct from "../components/User/UserPages/UserProduct";
import UserCheckout from "../components/User/UserPages/UserCheckout";
import UserLogin from "../components/User/UserLogin";
import UserOrders from "../components/User/UserOrders";
import UserSearch from "../components/User/UserSearch";
import Affiliate from "../components/Affiliate";
import AffiliateDashboard from "../components/Affiliate/AffiliateDashboard";
import AffiliateLogin from "../components/Affiliate/AffiliateLogin";
import AffiliateProduct from "../components/Affiliate/AffiliateProduct";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, resetCart } from "../store/slices/UserDetailsSlice";
import { setLoader } from "../store/slices/UserJourneyDataSlice";
import { setApiBaseUrl } from "../store/slices/apiBaseUrlSlice";
import { setBaseUrl } from "../utils/setBaseUrl";
import { setUserData } from "../utils/userUtils"
const App = () => {
  const dispatch = useDispatch();
  const userAccessToken = sessionStorage.getItem('userAccessToken')
  const userSession = JSON.parse(sessionStorage.getItem('userSession'))
  const { baseUrl } = useSelector((state) => state.apiBaseUrl)
  const checkitemmerged = sessionStorage.getItem("itemsMerged")
  const itemsMerged = JSON.parse(checkitemmerged);

  const getUserData = async () => {
    console.log("Starting getUserData function");
    dispatch(setLoader(true));

    try {
      // Step 1: Create or fetch user data
      console.log("Sending customer search/create request...");
      const response = await axios.post(`${baseUrl}/api/customer-search-create/`, {
        username: userSession?.user?.phoneNumber,
        password: userSession?.user?.phoneNumber,
      });
      const createData = response?.data;
      console.log("Customer create response:", createData);

      if (!createData?.customer_id) {
        throw new Error("Customer ID not found");
      }

      // Step 2: Fetch cart items from the server
      console.log("Fetching customer data...");
      const customerResponse = await axios.get(`${baseUrl}/api/customers/${createData?.customer_id}/`);
      const serverCartItems = customerResponse?.data?.cart_items_detail?.map((obj) => ({
        ...obj.item,
        quantity: obj.quantity,
      })) || [];
      console.log("Server cart items:", serverCartItems);

      // Step 3: Fetch local cart items and merge
      const localCartItems = JSON.parse(localStorage.getItem("cartItems") || "[]");
      console.log("Local cart items:", localCartItems);
      const itemsMerged = JSON.parse(sessionStorage.getItem("itemsMerged") || "false");

      let mergedCartItems = [...serverCartItems];
      if (!itemsMerged) {
        console.log("Merging local cart items with server cart items...");
        localCartItems.forEach((localItem) => {
          const existingItem = mergedCartItems.find(
            (serverItem) => String(serverItem.id) === String(localItem.id)
          );
          if (existingItem) {
            console.log(`Merging quantities for item ID ${localItem.id}`);
            existingItem.quantity += localItem.quantity;
          } else {
            console.log(`Adding new local item ID ${localItem.id}`);
            mergedCartItems.push(localItem);
          }
        });

        // Step 4: Update server with merged cart
        console.log("Updating server with merged cart...");
        await axios.patch(`${baseUrl}/api/customers/${createData?.customer_id}/`, {
          cart_items: mergedCartItems.map(({ id, name, quantity }) => ({
            item: id,
            item_name: name,
            quantity: quantity
          }))
        });

        sessionStorage.setItem("itemsMerged", JSON.stringify(true));
      }

      // Step 5: Update storage and Redux
      console.log("Updating storage and Redux with merged cart items...");
      sessionStorage.setItem("cartItems", JSON.stringify(mergedCartItems));
      localStorage.setItem("cartItems", JSON.stringify(mergedCartItems));
      dispatch(resetCart(mergedCartItems));
      console.log("Cart successfully updated.");
    } catch (error) {
      console.error("Error in getUserData:", error.response?.data || error.message);
    } finally {
      dispatch(setLoader(false));
      console.log("Finished getUserData function");
    }
  };

  useEffect(() => {
    if (userAccessToken) {
      getUserData();
    }
  }, [userAccessToken])

  useEffect(() => {
    const cartLocalItems = localStorage.getItem('cartItems')
    if (!userAccessToken && cartLocalItems) {
      dispatch(addToCart(JSON.parse(cartLocalItems)))

      // dispatch(resetCart(JSON.parse(cartLocalItems)));
    }
  }, [])


  return (
    <Router>
      <Routes>
        {/* User Routes */}
        {/* <Route path="/" element={<HomePage />} /> */}
        {/* User Routes wrapped with UserLayout */}
        <Route element={<UserLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="login" element={<UserLogin />} />
          <Route path="affiliate-registration" element={<Affiliate />} />
          <Route path="affiliate" element={<AffiliateLogin />} />
          <Route path="affiliate-dashboard" element={<AffiliateDashboard />} />
          <Route path="cart" element={<UserCart />} />
          <Route path="checkout" element={<UserCheckout />} />
          <Route path="orders" element={<UserOrders />} />
          <Route path="/category/:categoryId" element={<UserCategory />} />
          <Route
            path="/category/:categoryId/subcategory/:subCategoryId"
            element={<UserSubCategory />}
          />
          <Route
            path="category/:categoryId/subcategory/:subCategoryId/product/:productId"
            element={<UserProduct />}
          />
          <Route
            path="/api/share/:UID"
            element={<AffiliateProduct />}
          />
          <Route
            path="search/:searchQuery"
            element={<UserSearch />}
          />
        </Route>
        {/* Admin Routes */}
        <Route
          path="/admin-dashboard/"
          element={
            <AdminRoute>
              <AdminLayout />
            </AdminRoute>
          }
        >
          <Route path="categories" element={<Categories />} />
          <Route path="subCategories" element={<SubCategories />} />
          <Route path="products" element={<Products />} />
          <Route path="orders" element={<Orders />} />
          <Route path="addAdvertisement" element={<AddAdvertise />} />
          <Route path="changeWebInfo" element={<ChangeWebInfo />} />
        </Route>
        {/* Public Routes */}
        <Route path="/admin-login" element={<AdminSignupLogin />} />
        {/* Catch-all route for 404 Not Found */}
        <Route path="*" element={<NotFound />} />{" "}
        {/* This handles all unmatched routes */}
      </Routes>
    </Router>
  );
};

export default App;
