import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  addresses: []
}

const UserDetailsSlice = createSlice({
  name: "UserDetails",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      // state.cartItems.push(action.payload);
      state.cartItems = [...current(state).cartItems, action.payload].flat();
    },
    setAddresses: (state, action) => {
      state.addresses = action.payload;
    },
    updateCartQuantity: (state, action) => {
      const item = state.cartItems.find(item => item.id === action.payload.id);
      if (item) {
        item.quantity = action.payload.quantity;
      }
    },
    removeFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter(item => item.id !== action.payload);
    },
    resetCart: (state, action) => {
      state.cartItems = action.payload;
    }
  },
});

export const { addToCart, updateCartQuantity, removeFromCart, setAddresses, resetCart } = UserDetailsSlice.actions;
export default UserDetailsSlice.reducer;