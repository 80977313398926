import React, { useState, useEffect } from 'react';
import "./ProductComponent.css"; // Importing the CSS file for styling

const ProductComponent = () => {
    const [products, setProducts] = useState([]);
    const [advertImage, setAdvertImage] = useState(null);
    const [advertLink, setAdvertLink] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://quirckart.in/api/adnew/');
                const data = await response.json();

                const fetchedProducts = [];
                for (let i = 1; i <= 4; i++) {
                    const image = data[0][`ibox${i}`];
                    const item = data[0][`item_ibox${i}`];

                    if (image && item) {
                        const productLink = `https://quirckart.in/category/${item.category_id}/subcategory/${item.subcategory_id}/product/${item.id}`;
                        fetchedProducts.push({
                            name: item.name || `Product ${i}`,
                            image,
                            discount: "Special Offer", // Replace with dynamic discount data if available
                            link: productLink,
                        });
                    }
                }

                const advertImage = data[0].big1 || null;
                const advertItem = data[0].item_big1;
                const advertLink = advertItem
                    ? `https://quirckart.in/category/${advertItem.category_id}/subcategory/${advertItem.subcategory_id}/product/${advertItem.id}`
                    : null;

                setProducts(fetchedProducts);
                setAdvertImage(advertImage);
                setAdvertLink(advertLink);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="flex-container">
            {/* Products List */}
            <div className="products">
                <div className="product-grid">
                    {products.map((product, index) => (
                        <div key={index} className="product-card border">
                            <a href={product.link} target="_blank" rel="noopener noreferrer">
                                <img src={product.image} alt={product.name} className="w-full mb-2" />
                            </a>
                            <h3 className="text-lg">{product.name}</h3>
                            <p className="text-green-600">{product.discount}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Advertisement Image */}
            <div className="advertisement">
                {advertImage && (
                    <img src={advertImage} alt="Advertisement" className="w-full h-full object-cover rounded-lg" />
                )}
                {advertLink && (
                    <div className="advert-button">
                        <a href={advertLink} target="_blank" rel="noopener noreferrer">
                            <button className="explore-button">Explore Now</button>
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductComponent;
