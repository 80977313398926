
import { Typography, Card, CardContent, CardMedia } from "@mui/material";
import "./UserCategory.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { setCatAndSubData } from "../../../../store/slices/UserJourneyDataSlice";
import axios from "axios";
import Image from "../../../GlobalComponents/Image";

function UserCategory() {
  const dispatch = useDispatch();
  const { baseUrl } = useSelector((state) => state.apiBaseUrl)
  const { catAndSubData } = useSelector((state) => state.userJourneyData);
  const [filteredSub, setFilteredSub] = useState([]);
  const { categoryId } = useParams();
  console.log("cat and subcat data in user category", catAndSubData);
  useEffect(() => {
    const filteredSubData = catAndSubData?.filter(
      (data) => data?.id == categoryId
    );
    console.log('fileterds afalsifeu', filteredSubData)
    setFilteredSub(filteredSubData);
  }, [catAndSubData]);

  // useEffect(() => {
  //   const getCatAndSubData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${baseUrl}/api/list/`
  //       );
  //       dispatch(setCatAndSubData(response?.data));
  //     } catch (error) {
  //       console.log(
  //         "error while getting user category data",
  //         error?.response?.message
  //       );
  //     }
  //   };
  //   getCatAndSubData();
  // }, []);

  useEffect(() => {
    console.log("Scroll to top");
    window.scrollTo(0, 0);
  }, [categoryId]);


  return (
    <div className="p-4">
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          paddingLeft: "1.2rem",
          marginBottom: "2rem",
          marginTop: "1rem",
          fontWeight: "bold",
        }}
      >
        {filteredSub?.[0]?.name}
      </Typography>

      <div className="grid-container">
        {filteredSub?.[0]?.subcategories &&
          filteredSub?.[0]?.subcategories.length > 0 &&
          filteredSub?.[0]?.subcategories.map((subcategory) => (
            <Link
              key={subcategory?.id}
              to={`/category/${categoryId}/subcategory/${subcategory?.id}`}
              style={{ textDecoration: "none" }}
            >
              <Card className="subcategory-item">
                <Image
                  // component="img"
                  src={subcategory?.image}
                  alt={subcategory?.name}
                  className={"subcategory-image"}
                  styles={{objectFit:'contain'}}
                />
                {/* <CardMedia
                  component="img"
                  image={subcategory?.image}
                  alt={subcategory?.name}
                  className="subcategory-image"
                /> */}
                <CardContent className="subcategory-content">
                  <Typography
                    textAlign={"left"}
                    variant="subtitle1"
                    className="subcategory-name"
                  >
                    {subcategory?.name}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          ))}
      </div>
    </div>
  );
}

export default UserCategory;
