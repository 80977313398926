// src/utils/config.js
let baseUrl = "https://quirckart.in"; // Default value


export const setBaseUrl = (url) => {
    baseUrl = url;
};

export const getBaseUrl = () => {
    return baseUrl;
};
