import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categoryData: [],
    subcategoryData: [],
    productData: [],
    catAndSubData: [],
    insightsData:[],
    advData:[],
    loading:false,
};


const UserJourneyDataSlice = createSlice({
  name: "UserJourneyData",
  initialState,
  reducers: {
    setCategoryData: (state, action) => {
      state.categoryData = action.payload;
    },
    setSubcategoryData: (state, action) => {
      state.subcategoryData = action.payload;
    },
    setProductData: (state, action) => {
      state.productData = action.payload;
    },
    setCatAndSubData:(state,action) =>{
        state.catAndSubData = action.payload;
    },
    setInsightsData:(state,action)=>{
      state.insightsData = action.payload;
    },
    setAdvData:(state,action)=>{
      state.advData = action.payload;
    },
    setLoader:(state,action)=>{
      state.loading=action.payload;
    }
  },
  
});

export const {
  setCategoryData,
  setSubcategoryData,
  setProductData,
  setCatAndSubData,
  setInsightsData,
  setAdvData,
  setLoader
} = UserJourneyDataSlice.actions;
export default UserJourneyDataSlice.reducer;