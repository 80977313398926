// import React from 'react';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import './HomeAdv.css'; // Make sure to create this CSS file for custom styles
// // import { homeAdvData as adData } from '../../../utils/dummyData';
// import Image from '../../GlobalComponents/Image';
// import { useSelector } from 'react-redux';

// function ScrollHomeAdv() {
//     const {advData} = useSelector(state=>state.userJourneyData)
//     console.log("advData data in scrollHomeadv", advData);
    
//     let landscapeImages = advData?.[0]?.landscape_images;

    
//     const settings = {
//         // dots: true, // Shows navigation dots
//         infinite: true, // Infinite looping
//         speed: 500, // Transition speed
//         slidesToShow: 1, // Shows one slide at a time
//         slidesToScroll: 1, // Scrolls one slide at a time
//         autoplay: true, // Enables autoplay
//         autoplaySpeed: 3000, // 3 seconds per slide
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 1, // Adjusts to show one slide for larger screens
//                 }
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 1, // Adjusts to show one slide for medium screens
//                 }
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1, // Adjusts to show one slide for small screens
//                 }
//             }
//         ]
//     };


//     return (
//         <div className="ads-carousel"  >
//             <Slider {...settings}>
//                 {landscapeImages?.map((ad, index) => (
//                     <div key={index} className="ad-item">
//                         <Image src={ad.image} alt={"Landscape"} className="ad-image" style={{border:'none'}}/>
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//     );
// }

// export default ScrollHomeAdv;

import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './HomeAdv.css'; // Make sure to create this CSS file for custom styles
import Image from '../../GlobalComponents/Image';

function ScrollHomeAdv() {
    const [advData, setAdvData] = useState([]);
    
    useEffect(() => {
        // Fetch the data from the API
        const fetchData = async () => {
            try {
                const response = await fetch('https://quirckart.in/api/adnew/');
                const data = await response.json();
                setAdvData(data);
            } catch (error) {
                console.error("Error fetching advertisement data:", error);
            }
        };

        fetchData();
    }, []);

    // Extract slideshow images and corresponding links
    const getLandscapeImages = () => {
        if (!advData || advData.length === 0) return [];

        return advData.map(item => {
            const slides = [];
            
            for (let i = 1; i <= 3; i++) {
                const slideshowKey = `slideshow${i}`;
                const itemKey = `item_slideshow${i}`;

                if (item[slideshowKey]) {
                    const category = item[itemKey]?.category_id ?? 'undefined';
                    const subcategory = item[itemKey]?.subcategory_id ?? 'undefined';
                    const product = item[itemKey]?.id ?? 'undefined';

                    slides.push({
                        image: item[slideshowKey],
                        url: `https://quirckart.in/category/${category}/subcategory/${subcategory}/product/${product}`
                    });
                }
            }

            return slides;
        }).flat();
    };

    const landscapeImages = getLandscapeImages();

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className="ads-carousel">
            {landscapeImages.length > 0 ? (
                <Slider {...settings}>
                    {landscapeImages.map((ad, index) => (
                        <div key={index} className="ad-item">
                            <a 
                                href={ad.url} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="ad-link"
                            >
                                <Image src={ad.image} alt={`Slideshow ${index + 1}`} className="ad-image" />
                            </a>
                        </div>
                    ))}
                </Slider>
            ) : (
                <p></p>
            )}
        </div>
    );
}

export default ScrollHomeAdv;

