import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

const GlobalLoader = ({ children }) => {
    // Correctly access the `loading` state
    const loading = useSelector((state) => state.userJourneyData?.loading || false);

    return (
        <>
            {loading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 99999999,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {children}
        </>
    );
};

export default GlobalLoader;
