import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Box,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MaterialReactTable } from "material-react-table";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toastSuccess, toastError } from "../../../utils/toastUtil";
import axios from "axios";
import Image from "../../GlobalComponents/Image";
import { useNavigate } from "react-router-dom";
import { logoutAffiliate } from "../../../utils/userUtils";
import toast from "react-hot-toast";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Chip from "@mui/material/Chip";
// import IconButton from '@mui/material/IconButton';
import Divider from "@mui/material/Divider";
import AffiliateSearchProduct from "./AffiliateSearchProduct";
import { set } from "react-hook-form";
import { useSelector } from "react-redux";

const AffiliateDashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [token, setToken] = useState("");
  const [previewData, setPreviewData] = useState([]);
  const [loading, setLoading] = useState(false); // For loading states if needed
  const [openTokenModal, setOpenTokenModal] = useState(false);
  const [affiliateTokens, setAffiliateTokens] = useState([]);
  const { baseUrl } = useSelector((state) => state.apiBaseUrl)

  // Fetch categories on mount
  const getUserCategoriesData = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/list/`
      );
      // const response = await axios.get(`${baseUrl}/api/categories/');
      setCategories(response?.data || []);
    } catch (error) {
      toastError("Failed to load categories");
      console.log(
        "Error while getting user category data",
        error?.response?.message
      );
    }
  };

  const generateAffiliateLink = async () => {
    setIsLoading(true);
    let toastId = toast.loading("Generating link...");
    try {
      const response = await axios.post(
        `${baseUrl}/api/affiliate-links/`,
        {
          affiliate_username: sessionStorage.getItem("affiliate_username"),
          item: selectedItem,
        }
      );
      if (response?.data?.link) {
        // Original URL
        // Original URL
        console.log("response coming from generating link", response)
        const originalUrl = response?.data?.link;

        // Extract the UID value
        const uidValue = originalUrl.split("/share")[1];

        console.log("uidValue", uidValue)
        // Remove slashes from the UID value
        const sanitizedUID = uidValue.replace(/\//g, "");
        console.log('sanetiexd uid', sanitizedUID)
        // Get the current website's hostname and protocol
        const currentHost =
          window.location.protocol + "//" + window.location.hostname;
        // Construct the new URL with sanitized UID as a query parameter
        const newUrl = `${currentHost}/api/share/${sanitizedUID}`;
        
        console.log(newUrl); // Output will show the updated URL

        setToken(newUrl);
        // setToken(response?.data?.link);
        setOpenTokenModal(true);
        setIsLoading(false);
        let message =
          response?.data?.status?.slice(0, 1).toUpperCase() +
          response?.data?.status?.slice(1);
        toastSuccess(message);
        toast.dismiss(toastId);
      }
    } catch (error) {
      toast.dismiss(toastId);
      setIsLoading(false);
      toastError("Failed to generate link");
    }
  };

  const fetchAffiliateTokenData = async () => {
    try {
      let affiliate_username = sessionStorage.getItem("affiliate_username");
      const response = await axios.get(
        `${baseUrl}/affiliate/${affiliate_username}/links/`
      );
      console.log("response", response);
      if (response?.data?.affiliate_username) {
        setAffiliateTokens(response?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAffiliateTokenData();
  }, [token]);

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);
    setSelectedSubCategory("");
    setSelectedItem("");
    setItems([]);

    // Fetch subcategories based on category ID
    const selectedCategory = categories.find(
      (category) => category.id === categoryId
    );
    setSubCategories(selectedCategory?.subcategories || []);
  };

  const handleSubCategoryChange = (event) => {
    const subCategoryId = event.target.value;
    setSelectedSubCategory(subCategoryId);
    setSelectedItem("");

    // Fetch items for the selected subcategory
    const getCatAndSubData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/subfind/?q=${subCategoryId}`
        );
        setItems(response?.data?.results || []);
      } catch (error) {
        toastError("Failed to load items");
        console.log("Error while fetching items data", error);
      }
    };
    getCatAndSubData();
  };

  const handleGetToken = async () => {
    generateAffiliateLink();
  };

  const handleCopyToken = (token) => {
    navigator.clipboard.writeText(token);
    toastSuccess("Token copied to clipboard!");
  };

  const columns = [
    { accessorKey: "category", header: "Category" },
    { accessorKey: "subCategory", header: "Subcategory" },
    { accessorKey: "item", header: "Product" },
    {
      accessorKey: "token",
      header: "Token",
      Cell: ({ cell }) => (
        <Button
          variant="contained"
          onClick={() => handleCopyToken(cell.getValue())}
        >
          Copy Token
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (
      !sessionStorage.getItem("affiliateAccess") &&
      !sessionStorage.getItem("affiliateRefresh")
    ) {
      navigate("/");
    } else {
      console.log("get affiliate data");
      getUserCategoriesData();
    }
  }, []);

  const handleLogout = () => {
    logoutAffiliate(navigate);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <Typography
          variant="h6"
          className="text-2xl md:text-4xl font-bold"
          gutterBottom
        >
          Affiliate Dashboard
        </Typography>
        <Button variant="contained" onClick={handleLogout}>
          Logout Affiliate
        </Button>
      </Box>
      <Box className="mb-4 w-full flex">
        <Chip
          label={"Your Tokens: " + affiliateTokens?.total_tokens || 0}
          color="success"
          variant="outlined"
          sx={{ fontWeight: "bold" }}
        />
      </Box>
      <Divider sx={{ margin: "1rem 0" }}>
        <Chip label="Generate by Search" size="small" />
      </Divider>

      <AffiliateSearchProduct
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setToken={setToken}
        setOpenTokenModal={setOpenTokenModal}
      />
      <Divider sx={{ margin: "1rem 0" }}>
        <Chip label="Genearate by Select" size="small" />
      </Divider>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              label="Category"
            >
              {categories?.map((category) => (
                <MenuItem key={category?.id} value={category?.id}>
                  {category?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth disabled={!selectedCategory}>
            <InputLabel>Subcategory</InputLabel>
            <Select
              value={selectedSubCategory}
              onChange={handleSubCategoryChange}
              label="Subcategory"
            >
              {subCategories.map((subCategory) => (
                <MenuItem key={subCategory.id} value={subCategory.id}>
                  {subCategory.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth disabled={!selectedSubCategory}>
            <InputLabel>Item</InputLabel>
            <Select
              value={selectedItem}
              onChange={(e) => setSelectedItem(e.target.value)}
              label="Item"
            >
              {items?.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  className="flex items-center"
                >
                  <img
                    src={item?.images?.[0]}
                    alt={item.name}
                    style={{ width: "30px", marginRight: "10px" }}
                    lazyLoad
                  />
                  <span className="text-primary font-bold mr-2">
                    Token Price:{item?.token}
                  </span>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            disabled={
              !selectedCategory ||
              !selectedSubCategory ||
              !selectedItem ||
              isLoading
            }
            onClick={handleGetToken}
          >
            Generate Link
          </Button>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h6">Previous Generated Tokens</Typography>
        <MaterialReactTable
          columns={columns}
          data={
            affiliateTokens?.links?.length > 0 && affiliateTokens?.links
            //   : [{ category: "-", subCategory: "-", item: "-", token: "-" }]
          }
        />
      </Box>
      <Dialog
        open={openTokenModal}
        onClose={() => setOpenTokenModal(false)}
        fullWidth
        maxWidth="sm" // Adjusts the max width of the modal based on the screen size
        PaperProps={{
          sx: {
            padding: { xs: 2, sm: 3, md: 3 }, // Adjust padding based on screen size
            borderRadius: 2, // Rounded corners for better UI
            bgcolor: "background.paper", // Adjust background color
            boxShadow: 24, // Elevation for a polished look
          },
        }}
      >
        <DialogTitle>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            Generated Token
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenTokenModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 2,
                  border: "1px solid lightgray",
                  borderRadius: 1,
                  backgroundColor: "#f9f9f9",
                  wordBreak: "break-all", // Ensures long strings break within words
                  overflowWrap: "break-word", // Break long words
                  maxWidth: "100%", // Ensure it does not overflow its container
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    marginRight: 1,
                    textAlign: "center",
                    fontSize: { xs: "1rem", sm: "1.2rem" }, // Responsive font size
                    wordBreak: "break-all", // Ensures token text breaks within words
                  }}
                >
                  {token}
                </Typography>
                <Tooltip title="Copy to clipboard">
                  <IconButton onClick={() => handleCopyToken(token)}>
                    <ContentCopyIcon
                      sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", paddingBottom: 2 }}>
          <Button
            onClick={() => setOpenTokenModal(false)}
            variant="contained"
            color="primary"
            sx={{
              borderRadius: 2,
              fontWeight: "bold",
              width: { xs: "100%", sm: "50%" }, // Full width for small screens, auto for larger
              padding: { xs: "10px", sm: "12px 24px" },
              textTransform: "none",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AffiliateDashboard;
