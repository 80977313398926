

import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  Rating,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { styled } from "@mui/system";
import { handleAddToCart } from "../../../utils/userUtils";
import { useDispatch, useSelector } from "react-redux";
import Image from "../../GlobalComponents/Image";
import axios from "axios";
import { setLoader } from "../../../store/slices/UserJourneyDataSlice";
// import "./ProductModal.css"; // Add a custom CSS file for smooth transition

// Custom styles for the modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "500px", // Limit max width for better responsiveness
  maxHeight: "90vh", // Decreased height
  overflowY: "auto", // Add scroll if content overflows
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px", // Add some rounded corners
  transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out", // Smooth transition effect
};

// Styled box for discount label
const DiscountLabel = styled(Typography)({
  color: "green",
  fontWeight: "bold",
  marginTop: "0.5rem",
});

function ProductModal({ open, onClose, product, productId }) {
  const { baseUrl } = useSelector((state) => state.apiBaseUrl)
  console.log("product in product modal", product)
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);

  // const handleQuantityChange = (event) => {
  //   const newQuantity = parseInt(event.target.value, 10) || 1; // Default to 1 if NaN
  //   setQuantity(Math.min(Math.max(1, newQuantity), productData.stock || 1));
  // };

  // const handleDecrease = () => {
  //   setQuantity((prev) => Math.max(1, prev - 1));
  // };

  // const handleIncrease = () => {
  //   setQuantity((prev) => prev + 1);
  // };

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10) || 1; // Default to 1 if NaN
    setQuantity(Math.min(Math.max(1, newQuantity), productData.stock || 1));
  };

  const handleDecrease = () => {
    setQuantity((prev) => Math.max(1, prev - 1));
  };

  const handleIncrease = () => {
    setQuantity((prev) => Math.min(prev + 1, productData.stock || 1));
  };

  const [productData, setProductData] = useState({});
  useEffect(() => {
    async function fetchSingleProduct() {
      try {
        dispatch(setLoader(true));
        const response = await axios.get(
          `${baseUrl}/api/items/${product.id}/`
        );
        console.log("product idata is", response.data);
        setProductData(response.data);
        // window.scrollTo(0, 0);
      } catch (error) {
        console.log("Error fetching product data", error);
      }
      finally {
        dispatch(setLoader(false));
      }
    }
    fetchSingleProduct();
  }, [productId]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="product-modal" // Add a class for custom transition
      closeAfterTransition // Ensure the modal smoothly transitions when closed
    >
      <Box sx={style}>
        <div className="flex justify-between items-center mb-4">
          <Typography id="modal-title" variant="h6" component="h2">
            {product.name}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        {/* Adjusted Image with a maximum height */}
        <Image
          src={product?.images}
          alt={product.name}
          style={{ width: "100%", height: "200px", objectFit: "contain" }} // Image adjustment
          className="mb-4"

        />

        <Typography variant="body1" paragraph>
          {product.specification || "No description available."}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" color="text.primary">
            ₹{product.price}
          </Typography>
          {
            product?.stock &&

            <Typography variant="h6" className="text-red-500 ">
              {
                product?.stock > 10 ? <span className="text-green-500">In Stock</span> : "Only Few Left"
              }
            </Typography>
          }
        </Box>
        <DiscountLabel>{product?.discount}% Off</DiscountLabel>
        <div className="mt-2">
          <Rating value={4} readOnly precision={0.5} />{" "}
          {/* Adjust based on your data */}
        </div>
        <div className="flex flex-col md:flex-row items-center justify-between mb-4">
          {/* <Typography variant="body1" className="mr-2">
            Quantity
          </Typography> */}
          <div className="flex items-center">
            <Button
              variant="outlined"
              onClick={handleDecrease}
              size="small"
              sx={{ minWidth: "40px" }}
            >
              -
            </Button>
            <TextField
              value={quantity}
              onChange={handleQuantityChange}
              size="small"
              inputProps={{
                min: 1,
                max: productData.stock,
                style: { textAlign: "center" },
              }}
              sx={{ width: "50px", mx: 2 }}
            />
            <Button
              variant="outlined"
              onClick={handleIncrease}
              size="small"
              sx={{ minWidth: "40px" }}
            >
              +
            </Button>
          </div>
        </div>



        <Button
          variant="contained"
          color="primary"
          className="mt-4 w-full"
          startIcon={<ShoppingCartIcon />}
          onClick={() => handleAddToCart(product, dispatch, quantity)}
          disabled={productData.stock <= 0}
        >
          {productData.stock > 0 ? "Add to Cart" : "Out of Stock"}
        </Button>

        {productData.stock <= 10 && <div style={{ color: "red", marginTop: "10px" }}>!Hurry only few items left</div>}
      </Box>
    </Modal>
  );
}

export default ProductModal;
