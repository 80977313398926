import React, { useState, useEffect } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from './firebaseSetup.js';
import { Button, TextField, Box, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toastError, toastSuccess } from "../../../utils/toastUtil.js";
import { useDispatch } from "react-redux";
import { resetCart } from "../../../store/slices/UserDetailsSlice";
import axios from "axios";
import { getBaseUrl } from "../../../utils/setBaseUrl";

function UserLogin() {
  const [otp, setOtp] = useState("");
  const [mobile, setMobile] = useState("");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(180); // 3 minutes timer
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const baseUrl = getBaseUrl();

  useEffect(() => {
    let interval;
    if (resendDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendDisabled]);

  const handleTimer = () => {
    setTimer(180); // reset to 3 minutes
    setResendDisabled(true);
  };

  const fetchUserCart = async (phoneNumber) => {
    try {
      const response = await axios.post(`${baseUrl}/api/customer-search-create/`, {
        username: phoneNumber,
        password: phoneNumber
      });
      
      const userData = response?.data;
      if (userData?.customer_id) {
        const cartResponse = await axios.get(`${baseUrl}/api/customers/${userData.customer_id}/`);
        const cartData = cartResponse?.data;
        
        if (cartData?.cart_items_detail) {
          const cartItems = cartData.cart_items_detail.map(item => ({
            ...item.item,
            quantity: item.quantity
          }));
          
          // Update cart data in all storage locations
          localStorage.setItem("cartItems", JSON.stringify(cartItems));
          sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
          dispatch(resetCart(cartItems));
          
          // Update cart on server with phone number
          await axios.patch(`${baseUrl}/api/customers/${userData.customer_id}/`, {
            cart_items: cartItems.map(({ id, name, quantity }) => ({
              item: id,
              item_name: name,
              quantity: quantity
            })),
            phone: phoneNumber
          });
        }
      }
    } catch (error) {
      console.error("Error fetching/updating user cart:", error?.response?.data || error);
    }
  };

  const sendOtp = async () => {
    setLoading(true);
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', { size: 'invisible' });
      }
      const recaptcha = window.recaptchaVerifier;
      const confirmation = await signInWithPhoneNumber(auth, `+91${mobile}`, recaptcha);
      if (confirmation?.verificationId) {
        setUser(confirmation);
        handleTimer();
        toastSuccess("OTP sent successfully");
      }
    } catch (error) {
      toastError("Failed to send OTP. Try Again.");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    setLoading(false);
  };

  const verifyOtp = async () => {
    setLoading(true);
    try {
      const data = await user.confirm(otp);
      sessionStorage.setItem("userSession", JSON.stringify(data));
      sessionStorage.setItem("userId", data?.user?.uid);
      sessionStorage.setItem("userAccessToken", data?.user?.accessToken);
      
      // Fetch user cart data after successful login
      // await fetchUserCart(mobile);
      
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error(error);
      toastError("Failed to verify OTP. Please try again.");
    }
    setLoading(false);
  };

  const resendOtp = () => {
    sendOtp();
    setResendDisabled(true);
  };

  return (
    <Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    position: 'relative', // Set position for parent Box
    background: 'url("https://res.cloudinary.com/dv9iswy2f/image/upload/v1736162773/DALL_E_2025-01-06_16.56.00_-_A_vibrant_shopping_scene_featuring_people_walking_through_a_modern_shopping_mall_or_street_carrying_shopping_bags_with_the_logo_QUIRCKART_prominent_m6exs5.webp") center/contain no-repeat',
    backgroundSize: 'cover',
  }}
>
  <Box
    sx={{
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(15px)',
      padding: { xs: '1.5rem', sm: '2rem', md: '3rem' },
      borderRadius: '10px',
      boxShadow: '0 8px 32px rgba(0, 0, 0, 0.25)',
      width: '90%',
      maxWidth: '400px',
      textAlign: 'center',
    }}
  >
    <Typography
      variant="h4"
      mb={3}
      fontWeight="bold"
      sx={{
        fontFamily: 'Roboto, sans-serif',
        color: '#ffffff',
        textShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        fontSize: { xs: '1.5rem', md: '2rem' },
      }}
    >
      Login with OTP
    </Typography>

    <TextField
      label="Enter Mobile Number"
      variant="outlined"
      type="tel"
      value={mobile}
      onChange={(e) => setMobile(e.target.value)}
      sx={{
        mb: 3,
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '10px',
      }}
    />

    <Button
      variant="contained"
      color="primary"
      onClick={sendOtp}
      disabled={loading || !mobile}
      sx={{
        mb: 3,
        width: '100%',
        backgroundColor: '#007BFF',
        color: '#fff',
        fontSize: '1rem',
        fontWeight: 'bold',
        padding: '0.8rem',
        borderRadius: '10px',
        '&:hover': {
          backgroundColor: '#0056b3',
        },
      }}
    >
      {loading ? <>Send OTP<CircularProgress size={24} /></> : 'Send OTP'}
    </Button>

    {user && (
      <>
        <TextField
          label="Enter OTP"
          variant="outlined"
          type="tel"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          sx={{
            mb: 3,
            width: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '10px',
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={verifyOtp}
          disabled={loading || !otp}
          sx={{
            mb: 3,
            width: '100%',
            backgroundColor: '#28a745',
            color: '#fff',
            fontSize: '1rem',
            fontWeight: 'bold',
            padding: '0.8rem',
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: '#218838',
            },
          }}
        >
          {loading ? <>Verify OTP<CircularProgress size={24} /></> : 'Verify OTP'}
        </Button>

        <Typography
          variant="body2"
          sx={{
            mb: 3,
            color: '#ffffff',
            fontSize: '0.9rem',
          }}
        >
          Resend OTP in {timer}s
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          onClick={resendOtp}
          disabled={resendDisabled || loading}
          sx={{
            width: '100%',
            borderColor: '#ffffff',
            color: '#ffffff',
            fontSize: '1rem',
            fontWeight: 'bold',
            padding: '0.8rem',
            borderRadius: '10px',
            '&:hover': {
              borderColor: '#ccc',
            },
          }}
        >
          Resend OTP
        </Button>
      </>
    )}
  </Box>

  {/* ReCAPTCHA placed outside the login container */}
  <Box
    id="recaptcha"
    sx={{
      position: 'absolute',
      bottom: 0,
      width: '100%',
      textAlign: 'center',
      padding: '10px 0',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    }}
  ></Box>
</Box>

  );
}

export default UserLogin;
