import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toastSuccess, toastError, toastWarning } from "../../../../utils/toastUtil";
import UserAddress from "./UserAddress";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../../store/slices/UserJourneyDataSlice";
import { resetCart } from "../../../../store/slices/UserDetailsSlice";
import { baseUrl } from "../../../../store/slices/apiBaseUrlSlice";

const UserCheckout = () => {
  const { cartItems } = useSelector((state) => state.userDetailsData);
  console.log(cartItems, "this is cart")
  const { baseUrl } = useSelector((state) => state.apiBaseUrl)
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isZipCodeServicable, setIsZipCodeServicable] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({})
  const [shippingCost, setShippingCost] = useState(0);
  const navigate = useNavigate();
  const userLogin = Cookies.get("userLogin");
  const userDetailsFetch = JSON.parse(
    sessionStorage.getItem("userDetailsFetch")
  );
  const dispatch = useDispatch()

  const totalPrice = cartItems.reduce((total, item) => {
    const itemPrice = item.discount
      ? item.price - (item.price * item.discount) / 100
      : item.price;
    return total + itemPrice * item.quantity;
  }, 0);

  const totalDiscount = cartItems.reduce((total, item) => {
    if (item.discount) {
      const discountAmount = (item.price * item.discount) / 100;
      return total + discountAmount * item.quantity;
    }
    return total;
  }, 0);

  const totalAmountWithoutDiscount = cartItems.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);

  const weightOfItems = cartItems.reduce((total, item) => {
    return total + item.weight * item.quantity
  }, 0);

  const calculateShippingCost = async () => {
    dispatch(setLoader(true))
    try {


      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Token 454efe120c467091556c613bada8af5b3bab3bc5");

      const requestData = {
        md: "S",
        ss: "Delivered",
        o_pin: 110085,
        d_pin: selectedAddress.postal_code,
        cgm: weightOfItems,
        pt: "COD",
      }


      const response = await fetch(
        `${baseUrl}/fetch-delivery-charges/?md=${requestData.md}&ss=${requestData.ss}&d_pin=${requestData.d_pin}&o_pin=${requestData.o_pin}&cgm=${requestData.cgm}&pt=${requestData.pt}`,
        {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch shipping cost");
      }

      const result = await response.json(); // Assuming the API returns JSON
      console.log(result, "Shipping Cost API Response");

      // Store the shipping cost in state (adjust 'shipping_charge' as per API response)
      if (result[0]?.total_amount) {
        setShippingCost(result[0]?.total_amount);
      }
    } catch (error) {
      console.error("Error fetching shipping cost:", error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const clearServerCart = async () => {
    try {
      // Get user session data
      const userDetailsFetch = JSON.parse(sessionStorage.getItem("userDetailsFetch"));
      const userSession = JSON.parse(sessionStorage.getItem("userSession"));

      if (!userSession?.user?.phoneNumber) {
        throw new Error("User phone number not found");
      }

      // First get customer ID
      const response = await axios.post(`${baseUrl}/api/customer-search-create/`, {
        username: userSession.user.phoneNumber,
        password: userSession.user.phoneNumber
      });

      const createData = response?.data;
      if (!createData?.customer_id) {
        throw new Error("Customer ID not found");
      }

      // Clear cart items on server with proper format
      await axios.patch(`${baseUrl}/api/customers/${createData?.customer_id}/`, {
        cart_items: [], // Empty cart items array
        cart_items_detail: [] // Also clear detailed cart items
      });

      console.log("Server cart cleared successfully");
    } catch (error) {
      console.error("Error clearing server cart:", error?.response?.data || error.message);
      throw error; // Propagate error to handle it in ManifestOrder
    }
  };

  const ManifestOrder = async (orderId = null, paymentStatus = "pending") => {
    dispatch(setLoader(true));

    try {
      // Step 1: Post Order Details to Track User Orders
      const userId = JSON.parse(sessionStorage.getItem("userDetailsFetch"));
      const postData = {
        user: userId?.id,
        order_id: orderId,
        address: selectedAddress,
        delivery_status: "pending",
        payment_status: paymentStatus,
        coupon: {
          name: "",
          amount: "",
        },
        region: "po",
        subtotal: totalAmountWithoutDiscount + shippingCost,
        total_price: totalPrice + shippingCost,
        order_items: cartItems,
      };

      // Step 2: Call Manifest API (Delhivery API)
      console.log("here menifest starts")
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Token 454efe120c467091556c613bada8af5b3bab3bc5");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "sessionid=nq41ctes9iwno1m2i46djj3k0r6jjq0w");

      const raw = JSON.stringify({
        "pickup_location_id": 1,
        "return_details_id": 1,
        shipments: [
          {
            "country": "India",
            "city": selectedAddress.city || "Unknown",
            "seller_add": "",
            [paymentMethod == "COD" ? "cod_amount" : "total_amount"]: (totalPrice + shippingCost).toFixed(0),
            "return_phone": "7774855283",
            "seller_inv_date": "",
            "seller_name": "",
            "pin": selectedAddress.postal_code || "Unknown",
            "seller_inv": "",
            "state": selectedAddress.state || "Unknown",
            "add": selectedAddress.address_line1 || "Unknown",
            "payment_mode": paymentMethod == "COD" ? "COD" : "Prepaid",
            "shipping_mode": "S",
            "quantity": cartItems.length.toString(),
            "seller_cst": "",
            "seller_tin": "",
            "phone": selectedAddress.phone,
            "name": selectedAddress.name,
            "weight": weightOfItems,
            "qc": {
              "item": cartItems.map((item) => ({
                "images": item.images[0], // First image from the array
                "descr": item.specification, // Specification from the cart
                "pcat": item.subcategory || "Unknown", // Subcategory or fallback
                "item_quantity": item.quantity, // Product quantity
              })),
            },

          },
        ],
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      console.log("About to call Manifest API...");
      const manifestResponse = await fetch(
        "https://quirckart.in/cs/",
        requestOptions
      );

      if (!manifestResponse.ok) {
        throw new Error("Failed to create manifest.");
      }

      const manifestResult = await manifestResponse.json();
      console.log("Manifest API Response:", manifestResult);

      // Step 3: Clear cart on server first
      await clearServerCart();

      // Step 4: Clear cart locally
      dispatch(resetCart([])); // Reset cart in Redux
      localStorage.setItem("cartItems", JSON.stringify([]));
      sessionStorage.setItem("cartItems", JSON.stringify([]));
      sessionStorage.removeItem("billUserAddress");

      // Step 5: Show success and redirect
      toastSuccess("Order placed successfully!");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {
      console.error("Error in ManifestOrder:", error);
      toastError("Failed to place order. Please try again.");
    } finally {
      dispatch(setLoader(false));
    }
  };

  const getAddressDataFromChild = (data, basicDetails) => {
    setSelectedAddress((prev) => ({
      ...prev,
      ...data,
      ...basicDetails
    }))

    console.log(data, "this is selected address")
  }

  useEffect(() => {
    if (isZipCodeServicable) {
      calculateShippingCost();
    }
  }, [isZipCodeServicable]);


  const handlePlaceOrder = async () => {
    if (!paymentMethod) {
      toastWarning("Please select a payment method.");
      return;
    }

    if (paymentMethod == "Razorpay") {
      try {
        const formData = new URLSearchParams();
        formData.append("amount", `${Math.floor(totalPrice + shippingCost)}`);

        const response = await fetch(`${baseUrl}/initiate-payment/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data.status === "success") {
          initiateRazorpay(data.id); // Open Razorpay for payment
        } else {
          toastError("Payment initiation failed.");
        }
      } catch (error) {
        console.error("Error initiating payment:", error);
        toastError("Error: " + error.message);
      }
    } else if (paymentMethod == "COD") {
      // Call ManifestOrder directly for COD with 'pending' payment status
      await ManifestOrder(null, "pending");
    }
  };


  const initiateRazorpay = (orderId) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => {
      const options = {
        key: "ZkAUQ0NnsNpmCw97v5nI33n1",
        amount: `${Math.floor(totalPrice + shippingCost)}`,
        currency: "INR",
        name: "Acme Corp",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: orderId,
        handler: async function (response) {
          // On successful Razorpay payment
          if (
            response?.razorpay_payment_id &&
            response?.razorpay_order_id &&
            response?.razorpay_signature
          ) {
            await ManifestOrder(orderId, "successful"); // Call ManifestOrder with Razorpay order ID
          }
        },
        prefill: {
          name: userDetailsFetch?.name || "",
          contact: userDetailsFetch?.phone || "",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function () {
        toastError("Payment failed. Please try again.");
      });
      rzp1.open();
    };
    document.body.appendChild(script);
  };


  return (
    <>
      {sessionStorage.getItem("userToken") ||
        sessionStorage.getItem("userSession") ? (
        <Container maxWidth="lg" className="py-8">
          {!userLogin ? (
            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Paper elevation={3} className="p-4 mb-4">
                  <Typography variant="h6" className="font-bold mb-2">
                    Delivery Address
                  </Typography>

                  <UserAddress
                    setIsZipCodeServicable={setIsZipCodeServicable}
                    isZipCodeServicable={isZipCodeServicable}
                    getAddressDataFromChild={getAddressDataFromChild}
                  />
                </Paper>

                {isZipCodeServicable && (
                  <Paper elevation={3} className="p-4">
                    <Typography variant="h6" className="font-bold mb-2">
                      Payment Method
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="payment-method"
                        name="payment-method"
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <FormControlLabel
                          value="Razorpay"
                          control={<Radio />}
                          label="Razorpay"
                        />
                      </RadioGroup>

                      <RadioGroup
                        aria-label="payment-method"
                        name="payment-method"
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <FormControlLabel
                          value="COD"
                          control={<Radio />}
                          label="Cash On Delivery"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Paper>
                )
                }
              </Grid>

              {isZipCodeServicable && paymentMethod && (
                <Grid item xs={12} md={4}>
                  <Paper elevation={3} className="p-4">
                    <Typography variant="h6" className="font-bold mb-2">
                      Order Summary
                    </Typography>
                    <Box className="flex justify-between my-2">
                      <Typography>Sub Total</Typography>
                      <Typography>Rs. {totalAmountWithoutDiscount?.toFixed(0)}</Typography>
                    </Box>
                    <Box className="flex justify-between my-2">
                      <Typography>Discount</Typography>
                      <Typography>- Rs. {totalDiscount?.toFixed(0)}</Typography>
                    </Box>
                    <Box className="flex justify-between my-2">
                      <Typography>Shipping Cost</Typography>
                      <Typography>Rs. {shippingCost?.toFixed(0)}</Typography>
                    </Box>
                    <Box className="flex justify-between my-2">
                      <Typography>Total Payable</Typography>
                      <Typography>Rs. {(totalPrice + shippingCost)?.toFixed(0)}</Typography>
                    </Box>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className="mt-4"
                      onClick={handlePlaceOrder}
                    >
                      Place Order
                    </Button>
                  </Paper>
                </Grid>
              )}

            </Grid>
          ) : (
            <Typography variant="h6" className="font-bold">
              Please log in to complete the checkout process.
            </Typography>
          )}
        </Container>
      ) : (
        <div
          className="flex flex-col mt-16 items-center h-screen"
          style={{ fontFamily: "Poppins" }}
        >
          <h2 style={{ fontWeight: "bold" }}>Please Login First</h2>
          <Button
            style={{ width: "50%", fontWeight: "bold" }}
            onClick={() => navigate("/login")}
          >
            Go to Login
          </Button>
        </div>
      )}
    </>
  );
};

export default UserCheckout;
