// components/UserLayout.js
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import UserNavbar from "../UserNavbar";
import UserFooter from "../UserFooter";
import Breadcrumbs from "../Breadcrumbs";

const UserLayout = () => {
  const location = useLocation();
  const showBreadcrumbs = location.pathname !== '/'; // Don't show breadcrumbs on home page

  return (
    <>
      <UserNavbar />
      {/* Show breadcrumbs on all pages except home */}
      {showBreadcrumbs && <Breadcrumbs />}
      {/* Outlet renders the child route elements */}
      <div className="content min-h-screen">
        <Outlet />
      </div>
      <UserFooter />
    </>
  );
};

export default UserLayout;
